import { Link } from "gatsby"
import * as React from "react"
import logo from "../images/logo.png"

export const Nav = () => {
  return (
    <>
      <div className="flex flex-row items-center justify-center">
        <Link className="sm:hidden flex" to="/" title="hortensia.tech">
          <img
            className="w-52"
            src={logo}
            alt="logo"
            width={"100%"}
            height={"100%"}
          />
        </Link>
      </div>
      <div className="flex flex-row items-center justify-center gap-4 w-full p-4 sm:p-8 sm:h-[128px]">
        <Link to="/portfolio">Portfolio</Link>
        <Link to="/about">About</Link>
        <Link className="hidden sm:flex" to="/" title="hortensia.tech">
          <img
            className="w-52"
            src={logo}
            alt="logo"
            width={"100%"}
            height={"100%"}
          />
        </Link>
        <Link to="/blog">Blog</Link>
        <Link
          to="https://www.fiverr.com/share/Kodo2k"
          target="_blank"
          rel="noreferrer"
        >
          Contact
        </Link>
      </div>
    </>
  )
}
