import * as React from "react"
import { Nav } from "./nav"
import upwork from "../images/upwork.png"
import fiverr from "../images/fiverr.png"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div className="flex flex-col font-ptsans" data-is-root-path={isRootPath}>
      <header>
        <Nav />
      </header>
      <main>{children}</main>
      <footer className="flex flex-col sm:flex-row items-center justify-center p-8">
        <a
          className="flex title-font font-medium items-center md:justify-start justify-center "
          href="/"
        >
          <span className="ml-3 text-xl">hortensia.tech</span>
        </a>
        <p className="text-sm  sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © {new Date().getFullYear()} hortensia.tech —
          <a
            href="https://www.upwork.com/freelancers/~017422af03f1e940ef"
            className=" ml-1"
            rel="noopener noreferrer"
            target="_blank"
          >
            Anouar H.
          </a>
        </p>
        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a
            className="flex flex-col justify-end mr-2"
            href="https://www.upwork.com/freelancers/~017422af03f1e940ef"
          >
            <img
              className="w-20"
              src={upwork}
              alt="upwork-logo"
              width={"100%"}
              height={"100%"}
            />
          </a>
          <a href="https://www.fiverr.com/share/pyWYBN">
            <img
              className="w-20"
              src={fiverr}
              alt="fiverr-logo"
              width={"100%"}
              height={"100%"}
            />
          </a>
        </span>
      </footer>
    </div>
  )
}

export default Layout
